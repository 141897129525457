import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Card, Drawer, FloatButton, Form, Input, InputNumber, message, Table} from 'antd';
import {SettingFilled} from "@ant-design/icons";

axios.defaults.withCredentials = true;

type LoginForm = {
    code: string;
}

const Record: React.FC = () => {

    const [userName, setUserName] = useState<string>('');

    const [messageApi, contextHolder] = message.useMessage();

    const [open, setOpen] = useState(false);

    const [tableData, setTableData] = useState();

    const [loginBtnLoading, setLoginBtnLoading] = useState(false);

    const [selectBtnLoading, setSelectBtnLoading] = useState(false);

    const [limit, setLimit] = useState(30);


    useEffect(() => {
        const storageName = localStorage.getItem("userName");
        if (storageName) {
            login({code: storageName});
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, []);
    
    const onClose = () => {
        if (userName === '') {
            messageApi.warning("先配置信息才能进入");
        } else {
            setOpen(false);
        }
    };

    const login = (values: LoginForm) => {
        setLoginBtnLoading(true);
        const options = {
            method: 'POST',
            url: '/api/login.do',
            headers: {'content-type': 'application/x-www-form-urlencoded'},
            data: {code: values.code, password: '123456'}
        };
        axios.request(options).then(function (response) {
        }).catch(function (error) {
            if (error.response) {
                messageApi.error(error.response.data.msg);
            } else {
                messageApi.success("登录成功");
                setUserName(values.code);
                setOpen(false);
                selectRecord();
                localStorage.setItem("userName", values.code);
            }
        }).finally(() => {
            setLoginBtnLoading(false);
        });
    }

    const selectRecord = () => {
        setSelectBtnLoading(true);
        const options = {
            method: 'POST',
            url: '/api/face/faceRecognitionRecord/list.json',
            headers: {'content-type': 'application/x-www-form-urlencoded'},
            data: {page: '1', limit: limit}
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            setTableData(response.data.data);
        }).catch(function (error) {
            if (error.response) {
                messageApi.error(error.response.data.msg);
            }
        }).finally(() => {
            setSelectBtnLoading(false);
        });
    }

    const resetUser = () => {
        setOpen(true);
    }

    const columns = [
        {
            title: '部门名称',
            dataIndex: 'partName',
            key: 'partName',
        },
        {
            title: '姓名',
            dataIndex: 'studentName',
            key: 'studentName',
        },
        {
            title: '是否迟到',
            dataIndex: 'tooLate',
            key: 'tooLate',
        },
        {
            title: '是否早退',
            dataIndex: 'leaveEarly',
            key: 'leaveEarly',
        },
        {
            title: '上班打卡时间',
            dataIndex: 'triggerTime',
            key: 'triggerTime',
        },
        {
            title: '下班打卡时间',
            dataIndex: 'closedTime',
            key: 'closedTime',
        },
        {
            title: '打卡机',
            dataIndex: 'equipmentName',
            key: 'equipmentName',
        },
    ];


    return (
        <div style={{margin: "15px 25px 15px 25px"}}>
            {contextHolder}
            <Drawer title="登录账号配置"
                    placement="right"
                    onClose={onClose}
                    open={open}
                    maskClosable={false}
            >
                <Form onFinish={login}>
                    <Form.Item label="用户名" name={"code"}>
                        <Input/>
                    </Form.Item>
                    <Form.Item>
                        <Button loading={loginBtnLoading} style={{float: "right"}} type="primary" htmlType="submit">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>

            <Card
                title={"考勤数据"}
                hoverable
            >
                <InputNumber
                    addonBefore={"查询条数"}
                    style={{float: "left", width: "250px", margin: "0 0 10px 0"}}
                    min={30}
                    placeholder="输入查询条数，不能少于30条"
                    value={limit}
                    onChange={(value) => {
                        value && setLimit(value);
                    }}
                    onPressEnter={() => {
                        selectRecord();
                    }}
                />

                <Table loading={selectBtnLoading} columns={columns} dataSource={tableData}/>;
            </Card>

            <FloatButton
                icon={<SettingFilled rev={undefined}/>}
                onClick={resetUser}
            />
        </div>
    );
};

export default Record;