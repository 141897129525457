import React from 'react';
import './App.css';
import Record from './record';


function App() {
  return (
    <div className="App">
      <Record></Record>
    </div>
  );
}

export default App;
